.html-viewer h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
}

.html-viewer h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  /* 40px */
  font-weight: 500;
}

.html-viewer h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  /* 32px */
  font-weight: 400;
}

.html-viewer h4 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  /* 28px */
  font-weight: 400;
}

.html-viewer h5 {
  font-size: 1rem;
  line-height: 1.5rem;
  /* 28px */
  font-weight: 400;
}

.html-viewer ul {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 1.5rem;
}

.html-viewer ol {
  list-style-type: decimal;
  list-style-position: outside;
  padding-left: 1.5rem;
}

.html-viewer blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}

.html-viewer blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.html-viewer blockquote p {
  display: inline;
}

.html-viewer pre {
  border: 2px solid grey;
  width: 100%;
  border-left: 12px solid #C8C8C8;
  border-radius: 5px;
  padding: 14px;
  line-height: 24px;
  background-image: linear-gradient(180deg, #eee 50%, #fff 50%);
  background-size: 100% 48px;
  background-position: 0 14px;
  text-wrap: wrap;
}

.html-viewer pre[class^="language-"] {
  border: 2px solid grey;
  width: 100%;
  border-left: 12px solid #0174BA;
  border-radius: 5px;
  padding: 14px;
  line-height: 24px;
  background-image: linear-gradient(180deg, #eee 50%, #fff 50%);
  background-size: 100% 48px;
  background-position: 0 14px;
  text-wrap: wrap;
}

.html-viewer a {
  color: inherit;
  text-decoration: inherit;
}

.html-viewer a:hover {
  color: #0174BA;
}

.html-viewer hr {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #FBB03B;
  border: 1px solid;
}

@media (min-width: 768px) {
  .html-viewer h1 {
    font-size: 3rem;
    /* 48px */
    line-height: 1;
    font-weight: 700;
  }
  
  .html-viewer h2 {
    font-size: 2.25rem;
    /* 36px */
    line-height: 2.5rem;
    /* 40px */
    font-weight: 700;
  }
  
  .html-viewer h3 {
    font-size: 1.5rem;
    /* 24px */
    line-height: 2rem;
    /* 32px */
    font-weight: 500;
  }
  
  .html-viewer h4 {
    font-size: 1.25rem;
    /* 20px */
    line-height: 1.75rem;
    /* 28px */
    font-weight: 400;
  }
  
  .html-viewer h5 {
    font-size: 1.125rem;
    /* 18px */
    line-height: 1.75rem;
    /* 28px */
    font-weight: 400;
  }
  
}